<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					최근 본 상품
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- // nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox textbox--margin">
							<div class="textbox__icon">
								<img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
							</div>
							<h3 class="textbox__title">
								최근 본 숙소가 없어요
							</h3>
							<p class="textbox__description">
								 다양한 숙소를 만나고 <br>
								지금 숙소를 예약해 보세요!
							</p>
							<div class="textbox__button">
								<div class="row">
									<button type="button" class="btn btn-primary purple">
										숙소 보러가기
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>